// src/Section4.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Section3.css'; 

const Section3 = () => {
  return (
    <Container className="section4 text-center" id='joinus'>
      <Row>
        <Col>
        <h1 className="section4-title">Join us</h1>
          <p className="section4-text">
          Sam Bankman-Fried, sometimes known by his initials SBF, is an American entrepreneur, former billionaire, and investor. Sam is the song of two Stanford law professors and he studied physics at MIT. He is also one of the early adopters of crypto and founder and CEO of CEO of FTX, a cryptocurrency exchange. The exchange managed accounts for over one million users in 2022.

In September 2022, Sam Bankman-Fried offered Elon Musk billion to fund his purchase of Twitter. The information was released as part of the Twitter x Elon Musk lawsuit.

In November 2022, Sam Bankman-Fried’s net worth dropped 94% after FTX faced a solvency crisis which caused ripples across the cryptocurrency community. Bloomberg reported the loss of wealth as one of the largest one-day drops in the index’s history.
        </p>


        <img src="tweet.png" alt="tweet" className="img-fluid vibration" />
        </Col>
      </Row>
    </Container>
  );
};

export default Section3;
