// src/App.js
import React from 'react';
import './App.css';
import NavBar from './NavBar';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Footer from './Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';


function App() {
  return (
    <div className="App">
      <NavBar />
      <Section1 />
      <Section2 />
      <Section3 />
      <Footer />
    </div>
  );
}

export default App;
